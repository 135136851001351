import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useFetch from 'use-http';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';

import Authorisation from './Authorisation';
import ChooseAPollingPlace from './ChooseAPollingPlace';
import ChooseShifts from './ChooseShifts';
import ChooseTimes from './ChooseTimes';
import CONFIG from './config';
import ConfirmYourDetails from './ConfirmYourDetails';
import { Header } from './Header';
import ReviewYourChoices from './ReviewYourChoices';
import { SelfRosterHeader } from './SelfRosterHeader';
import { SelfRosterStepper } from './SelfRosterStepper';
import { somethingWentWrong } from './somethingWentWrong';
import { ThankYou } from './ThankYou';
import { RetrieveDetailsResponse } from './types/RetrieveDetailsResponse';
import { SelfRosterMode } from './types/SelfRosterMode';
import { useStyles } from './useStyles';
import { boothIsFull } from './util/boothIsFull';
import { createSlots } from './util/createSlots';
import { groupBoothsByDay } from './util/groupBoothsByDay';

const prepollSteps = [
  "Confirm your details",
  "Choose a polling place",
  "Choose day(s) & time(s)",
  "Review your choices",
];

const pollingdaySteps = [
  "Confirm your details",
  "Choose time(s)",
  "Choose a polling place",
  "Review your choices",
];

interface SelfRosterProps {
  mode: SelfRosterMode;
}

export default function SelfRoster({ mode }: { mode: SelfRosterMode }) {
  const steps = mode === "prepoll" ? prepollSteps : pollingdaySteps;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedBooth, selectBooth] = React.useState(null);
  const [selectedShifts, setSelectedShifts] = React.useState([]);
  const [selectedTimes, setSelectedTimes] = React.useState([]);
  const [needTalkStatus, setNeedTalkStatus] = React.useState(false);
  const [willTravelStatus, setWillTravelStatus] = React.useState(false);
  const [missingUserParams, setMissingUserParams] = React.useState(false);
  const [userParams, setUserParams] = React.useState({
    contactId: null,
    checksum: null,
    contactEmail: null,
  });
  const [userLocation, setUserLocation] = React.useState({
    lat: null,
    lng: null,
    manual: false,
  });
  const { get: getDetails, loading, error, data }: { get: any, loading: any, error: any, data: RetrieveDetailsResponse } = useFetch(
    `https://${process.env.REACT_APP_ENV === "production" ? 'zvjwo24psa' : 'w6aqptt111'}.execute-api.ap-southeast-2.amazonaws.com/${process.env.REACT_APP_ENV === "production" ? 'prod' : 'dev'}/retrieveDetails?contact_id=${userParams.contactId}&cs=${userParams.checksum}`
  );
  const { get: getBooths, loading: loadingBooths, error: errorBooths, data: dataBooths } = useFetch(
    `https://${process.env.REACT_APP_ENV === "production" ? 'zvjwo24psa' : 'w6aqptt111'}.execute-api.ap-southeast-2.amazonaws.com/${process.env.REACT_APP_ENV === "production" ? 'prod' : 'dev'}/retrieveBooths?lat=${userLocation.lat}&lng=${userLocation.lng}&prepoll=${mode === "prepoll" ? "true" : "false"}`
  );
  const {
    post: confirmShifts,
    loading: loadingConfirmStatus,
    error: errorConfirm,
    data: dataConfirm,
  } = useFetch(
    `https://${process.env.REACT_APP_ENV === "production" ? 'zvjwo24psa' : 'w6aqptt111'}.execute-api.ap-southeast-2.amazonaws.com/${process.env.REACT_APP_ENV === "production" ? 'prod' : 'dev'}/createShifts`
  );
  const slots =
    selectedShifts.length > 0 || (selectedTimes.length > 0 && selectedBooth)
      ? createSlots(selectedShifts, dataBooths.booths, selectedBooth, selectedTimes, mode)
      : null;
  const handleNext = async () => {
    setActiveStep(activeStep + 1);
    if (activeStep === 0) {
      console.log('boop');
      await getBooths();
    }
    window.scrollTo(0, 1);
    if (steps.length === activeStep + 1) {
      const shiftsRequest = await confirmShifts({
        contactId: userParams.contactId,
        checksum: userParams.checksum,
        notes: "(Self rostered)" + (needTalkStatus ? " Please call before marking shift as confirmed" : "") + (willTravelStatus ? " (Can travel)" : ""),
        slots,
      });
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    window.scrollTo(0, 1);
  };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (userParams.contactId) {
      if (!data) {
        getDetails();
      }
    } else {
      const params = new URLSearchParams(location.search);
      const contactId = params.get("contact_id");
      const checksum = params.get("cs");
      let contactEmail = params.get("em");
      console.log(window?.location?.hostname);
      if (
        (!contactId || !checksum)
      ) {
        setMissingUserParams(true);
        return;
      }
      if (!contactEmail) {
        contactEmail = "ag";
      }
      setUserParams({ contactId, checksum, contactEmail });
      history.replace(location.pathname);
    }
    console.log(data);
    if (data?.contact?.geo_code_1 && !userLocation.lat) {
      setUserLocation({ lat: data?.contact?.geo_code_1, lng: data?.contact?.geo_code_2, manual: false });
    }
  }, [userParams, data]);

  const getStepContent = (step, mode) => {
    switch (step) {
      case 0:
        return (
          <ConfirmYourDetails
            firstName={data?.contact.first_name}
            suburbName={data?.contact.city}
            electionName={CONFIG.electionname}
            userLocation={userLocation}
            setUserLocation={setUserLocation}
            mode={mode}
            contactEmail={userParams.contactEmail}
          />
        );
      case 1:
        if (mode === "pollingday") {
          return (
            <ChooseTimes
              selectedTimes={selectedTimes}
              setSelectedTimes={setSelectedTimes}
            />
          )
        }
        return (
          <ChooseAPollingPlace
            booths={groupBoothsByDay(dataBooths?.booths)}
            selectedBooth={selectedBooth}
            selectBooth={selectBooth}
            mode={mode}
          />
        );
      case 2:
        if (mode === "pollingday") {
          return (
            <ChooseAPollingPlace
              booths={dataBooths?.booths.filter((b) => !boothIsFull(b, selectedTimes)).length > 0 ? dataBooths?.booths.filter((b) => !boothIsFull(b, selectedTimes)) : dataBooths?.booths }
              selectedBooth={selectedBooth}
              selectBooth={selectBooth}
              mode={mode}
          />
          )
        }
        return (
          <ChooseShifts
            booths={dataBooths?.booths.filter(
              (booth) => booth.name === selectedBooth
            )}
            selectedShifts={selectedShifts}
            setSelectedShifts={setSelectedShifts}
          />
        );
      case 3:
        return (
          <ReviewYourChoices
            firstName={data?.contact.first_name}
            selectedBooth={selectedBooth}
            selectedShifts={selectedShifts}
            slots={slots}
            booths={dataBooths?.booths}
            needTalkStatus={needTalkStatus}
            setNeedTalkStatus={setNeedTalkStatus}
            willTravelStatus={willTravelStatus}
            setWillTravelStatus={setWillTravelStatus}
            mode={mode}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  };

  const isButtonDisabled = (mode) => {
    if (activeStep === 0 && userLocation.lat) {
      return false;
    }
    if (activeStep === 1 && ((mode === "prepoll" && selectedBooth) || (mode === "pollingday" && selectedTimes.length > 0))) {
      return false;
    }
    if (activeStep === 2 && ((mode === "prepoll" && selectedShifts.length > 0) || (mode === "pollingday" && selectedBooth))) {
      return false;
    }
    if (
      activeStep === 3 &&
      !loadingConfirmStatus &&
      !errorConfirm &&
      !dataConfirm
    ) {
      return false;
    }
    return true;
  };

  const isErrorState = error || errorBooths || errorConfirm || missingUserParams;
  const isLoadingState = loading || loadingBooths ||
    (activeStep === steps.length && loadingConfirmStatus);
  return (
    <React.Fragment>
      <CssBaseline />
      <Header />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <SelfRosterHeader mode={mode} />
          <React.Fragment>
          {isErrorState && activeStep === steps.length ? (
              ThankYou(data)
            ) : isErrorState ? (
              somethingWentWrong(userParams.contactEmail)
            ) : (
              <React.Fragment>
                {isLoadingState ? (
                  <div
                    style={{
                      width: "40px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      padding: "10px 0",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <React.Fragment>
                    {activeStep === steps.length ? (
                      ThankYou(data)
                    ) : (
                      <React.Fragment>
                        {getStepContent(activeStep, mode)}
                        {SelfRosterStepButtons(classes, activeStep, handleBack, handleNext, isButtonDisabled, mode, steps)}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <Authorisation />
      </main>
    </React.Fragment>
  );
}



function SelfRosterStepButtons(classes, activeStep: number, handleBack: () => void, handleNext: () => Promise<void>, isButtonDisabled: (mode: any) => boolean, mode: string, steps: string[]) {
  return <div className={classes.buttons}>
    {activeStep !== 0 && (
      <Button
        variant="contained"
        onClick={handleBack}
        className={classes.button}
      >
        Back
      </Button>
    )}
    <Button
      variant="contained"
      color="primary"
      onClick={handleNext}
      className={classes.button}
      disabled={isButtonDisabled(mode)}
    >
      {activeStep === steps.length - 1
        ? "Submit"
        : "Next"}
    </Button>
  </div>;
}

